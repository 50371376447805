@use 'styles/utils' as *;
@use 'styles/spacing' as *;

.container {
  @include stack();
  height: 100%;

  // Prevents elastic scroll effect on iOS Safari:
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.pageContainer {
  flex: 1;
  overflow-y: scroll;
}

.loadingIndicatorContainer {
  position: fixed;
  bottom: $bottom-bar-height + $s;
  right: $s;
}
