@use 'styles/spacing' as *;

.item {
  display: flex;
  align-items: center;
  height: $xxl;
  padding-left: $l;

  &:hover,
  &.active {
    background-color: #ffffff33;
  }

  &.active {
    font-weight: bold;
  }
}
