@use 'styles/colors' as *;
@use 'styles/spacing' as *;
@use 'styles/devices' as *;
@use 'styles/utils' as *;

.overlay {
  @include inset(0);
  position: fixed;
  background-color: $overlay;
  overflow-y: scroll;

  @include tablet-or-mouse-device {
    padding: $xl $xs $xxl;
  }
}

.window {
  background-color: $surface;
  overflow-x: hidden;

  @include mobile {
    @include stack;
    @include inset(0);
    position: absolute;
    width: 100%;

    &.small {
      margin-top: auto;
      margin-bottom: auto;
      height: fit-content;
    }
  }

  @include tablet-or-mouse-device {
    @include stack($m);
    margin-left: auto;
    margin-right: auto;
    min-width: 25rem;
    padding: $l;

    &.small {
      max-width: 30rem;
    }
    &.large {
      max-width: 58rem;
      min-height: 75vh;
    }
  }
}

.header {
  @include row($m);
  align-items: flex-start;

  @include mobile {
    padding: $s;
  }
}

.headerContentContainer {
  flex: 1;
}

.contentContainer {
  @include stack($l);
  flex: 1;

  @include mobile {
    gap: $xxl;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: $m $s;
  }
}
