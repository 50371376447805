@use 'styles/spacing' as *;
@use 'styles/utils' as *;
@use 'styles/devices' as *;

.container {
  &.row {
    @include row($s);
    flex-wrap: wrap;

    @include mobile {
      justify-content: center;
    }
  }

  &.column {
    @include stack($s);
    align-items: center;

    > * {
      width: 100% !important;
      text-align: center;
    }
  }
}
