@use 'styles/spacing' as *;
@use 'styles/utils' as *;

.contentContainer {
  @include stack($m);
}

.bodyContainer {
  @include stack($s);
}
