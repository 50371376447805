@use 'styles/spacing' as *;
@use 'styles/colors' as *;
@use 'styles/utils' as *;

.container {
  @include stack($m);
  justify-content: space-between;
  width: $sidebar-width;
  height: 100vh;
  padding-bottom: $l;
  background-color: $text-dark;
  color: $text-light;
}

.secondaryList {
  @include stack($m);
  align-items: center;
}

.scanner {
  @include stack($s);
  align-items: center;

  &:hover .qrIcon {
    transform: scale(1.05);
  }
}

.qrIcon {
  width: $xxl;
  height: auto;
  transition: transform 0.3s;
}
