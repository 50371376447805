@use 'styles/spacing' as *;
@use 'styles/colors' as *;
@use 'sass:math';

@mixin offset($icon-size) {
  $offset: math.max(0, ($min-touch-target - $icon-size) / 2);
  margin: -$offset;
}

.button {
  display: flex;
  min-width: $min-touch-target;
  min-height: $min-touch-target;
  aspect-ratio: 1;
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  &.hasHoverIndicator:hover {
    background-color: $system-light;
  }

  &:not(.hasHoverIndicator):hover {
    opacity: 0.85;
  }

  &.xs {
    @include offset($xs);
    width: $xs;
  }

  &.s {
    @include offset($s);
    width: $s;
  }

  &.m {
    @include offset($m);
    width: $m;
  }

  &.l {
    @include offset($l);
    width: $l;
  }

  &.xl {
    @include offset($xl);
    width: $xl;
  }

  &.xxl {
    @include offset($xxl);
    width: $xxl;
  }
}
