@use 'styles/spacing' as *;

.viewport {
  display: grid;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  grid-template-columns: $sidebar-width auto;
}

.sidebarContainer {
  position: fixed;
}

.pageContainer {
  grid-column: 2;
}

.loadingIndicatorContainer {
  position: fixed;
  right: $m;
  bottom: $m;
}
