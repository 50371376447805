@use 'styles/colors' as *;
@use 'styles/spacing' as *;
@use 'styles/utils' as *;
@use 'styles/typography' as *;
@use 'styles/devices' as *;

.container {
  @include row;
  height: $bottom-bar-height;
  background-color: $text-dark;
  justify-content: space-around;
  align-items: center;
}

.link {
  @include stack($xs);
  @include caption;
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: $text-light;
}

.active {
  font-weight: 800;
}

.icon {
  width: $m;
  height: auto;
}

.QRIcon {
  width: $xl;
  height: auto;
}
